import React from "react"
import Layout from "../components/layout"
import Button from "../components/Button"
import '../styles/product.less'

const Product = () => (
  <div className="fed-product">
      <Layout>
        <div className="fed-product-img">
          <div className="fed-product-text">
            <p>Coming Soon</p>
            <p>ただいま、詳細ページは作成中</p>
            <div className="fed-button">
              <Button to="/">ホームへ</Button>
            </div>
          </div>
        </div>
      </Layout>
  </div>
)

export default Product

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import classnames from 'classnames'
import './index.css';


const Header = () => {

  const [logoWhite, setLogoWhite] = useState(true)

  const onScroll = () => {
    if (document.documentElement.scrollTop > 52) {
      setLogoWhite(false)
    } else {
      setLogoWhite(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll,[])

    return () => window.removeEventListener('scroll', onScroll)
  })

  return (
    <header className={classnames('fed-header', { 'fed-header-white': !logoWhite })}>
      <div className="fed-logo">
        <div className={classnames({'fed-letter-t': !logoWhite, 'fed-letter-white': logoWhite })}>T</div>
        <div className={classnames({'fed-letter-h': !logoWhite, 'fed-letter-white': logoWhite })}>H</div>
        <div className={classnames({'fed-letter-s': !logoWhite, 'fed-letter-white': logoWhite })}>S</div>
      </div>
    </header>
  )
}

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header

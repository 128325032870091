import React from 'react'
import { Link } from 'gatsby'
import './index.less'

const Button = ({ to, children }) => {

    return (
        <Link className="fed-btn" to={to}>
            {children}
        </Link>
    )
}

export default Button